import React, { useState } from 'react'
import './post2.modules.css'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Fade from 'react-reveal/Fade'

const PostThumb = ( {props, state} ) => {

  const image = getImage(props.featuredImage.node.localFile)

  //console.log(state)

  return (
        <div>
          {/* <Fade bottom distance={'3rem'} appear={true}> */}
          <GatsbyImage image={image} alt={props.title}
          //loading={'eager'}
           />
          {/* </Fade> */}
        </div>
  )
}

export default PostThumb