import React from 'react'
import './index.css'
import Portfolio from '../components/Portfolio'
import {Helmet} from "react-helmet"


const portfolio = ( { location } ) => {

  return (
    <div>
    <Helmet>
        <title>Portfolio | Pablo Humanes: Estrategia, Diseño y Arquitecura</title>
        <script>{`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//pablohumanes.es/matomo/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
    </Helmet>
      <div className="App">
        <Portfolio />
    </div>
    </div>
  )
}

export default portfolio