import React, { useState } from 'react'
import './post2.modules.css'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Fade from 'react-reveal/Fade'

const Post2 = ( {props, state} ) => {

  const image = getImage(props.featuredImage.node.localFile)

  //console.log(props.uri)

  return (
        <div className="each-post">
          <div  className="post-image-rest">
          <Fade right mirror={state} delay={200} distance={'3rem'} appear={true} ssrFadeout={true}>
          <GatsbyImage image={image} alt={props.title}
          //loading={'eager'}
           />
          </Fade>
          </div>
          <Fade right mirror={state} cascade delay={300} distance={'3rem'} appear={true} ssrFadeout={true}>
          <div className='post-title'>
            <h2 className='post-title-text'>{props.title}</h2>
            <div className='post-title-text' dangerouslySetInnerHTML={{ __html: props.excerpt }} />
          <form action={'https://pablohumanes.es' + props.uri} target={'blank'}>
           <button type="submit" className="button-secondary">Leer más</button>
          </form>
          </div>
          </Fade>
          <div style={{height: '5.8rem'}} />
        </div>
  )
}

export default Post2

// onMouseEnter={() => setCurrentClass("each-post big-letters")} onMouseLeave={() => setCurrentClass("each-post")}