import React from 'react'
import { useState, Suspense } from "react"
import { useTransition, config, animated } from "react-spring"
import { useStaticQuery, graphql } from 'gatsby'
import Post2 from './Post2'
import PostThumb from './PostThumb'
import Loading from './Loading'
import './posts.css'
import './portfolio.css'
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Fade from 'react-reveal/Fade'
import { useSwipeable } from "react-swipeable"
import iconArrow from '../images/icon-arrow.svg'
import iconMosaic from '../images/icon-mosaic.svg'

const Portfolio = () => {

  const postData3 = useStaticQuery(graphql`
  query GetPosts1 {
    allWpPost(limit: 12) {
      nodes {
        id
        title
        featuredImage {
          node {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED,
                  width: 900
                  )
              }
            }
          }
        }
        uri
        excerpt
      }
    }
  }
`)

    const [isPortfolioVisible, setIsPortfolioVisible] = useState(true);
    const transition2 = useTransition(isPortfolioVisible, {
      from: { x: -40, y: 0, opacity: 0 },
      enter: { x: 0, y: 0, opacity: 1 },
      leave: { x: -80, y: 0, opacity: 0 },
      delay: 200,
      config: config.gentle,
    });
    
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const transition = useTransition(isMenuVisible, {
      from: { x: -40, y: 0, opacity: 0 },
      enter: { x: 0, y: 0, opacity: 1 },
      leave: { x: -80, y: 0, opacity: 0 },
      delay: 200,
      config: config.gentle,
    });

    const [count, setCount] = useState(0);
    const [fadeDirReversed, setFadeDirReversed] = useState(false);

    const post = postData3.allWpPost.nodes[count];
    const posts = postData3.allWpPost.nodes;

    function navButtonNext() {
      setFadeDirReversed(false);
      setCount(count + 1);
  }

    function navButtonPrev() {
      setFadeDirReversed(true);
      setCount(count - 1);
  }

    function selectClose(indice) {
      setCount(indice);
      setIsMenuVisible(false);
    }

  const handlers = useSwipeable({
    onSwipedLeft: () => {count < 11 && navButtonNext() },
    onSwipedRight: () => { count > 0 && navButtonPrev() },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
    
  return (
    <div {...handlers}>
      <div className='overlay' />
      <div>
        {transition2((style, item) =>
        item ? <animated.div style={style}>
      <div className='portfolio'>
        <TransitionGroup>
          <CSSTransition
            key={post.id}
            timeout={300}
            classNames={ fadeDirReversed ? "fade-reversed" : "fade"}
          >
            <Post2 key={post.id} props={post} state={fadeDirReversed} />
          </CSSTransition>
        </TransitionGroup>
      </div>
      </animated.div> : '')}</div>

        {count < 11 &&
        <Fade left bottom duration={300} distance={'2rem'} appear={true}>
          <button id='next' className='button-nav next' onClick={() => navButtonNext()}>
            <div className='disabled'>Siguiente</div>
            <img src={iconArrow} className='button-nav-icon next'></img>
          </button>
        </Fade>
          }
        { count > 0 &&  
        <Fade right bottom duration={300} distance={'2rem'} appear={true}>
          <button id='prev' className='button-nav prev' onClick={() => navButtonPrev()}>
            <img src={iconArrow} className='button-nav-icon prev'></img>  
            <div className='disabled'>Anterior</div>
          </button>
        </Fade>  
        }
        <Fade duration={1200} appear={true} delay={200}>
          <button id='postsmenu' className='button-nav menu disabled' onClick={() => setIsMenuVisible(!isMenuVisible)}>
            <img src={iconMosaic} className='button-nav-icon menu'></img>  
            <div className='disabled'>{count + 1} / {posts.length}</div>
          </button>
        </Fade>

      <div className='menu-container'>
        {transition((style, item) =>
        item ? <animated.div style={style}>
          <Suspense fallback={<Loading />}>
            <button className='button-close' onClick={() => setIsMenuVisible(!isMenuVisible)}>
                <h2>X</h2>
            </button>
            <div className='text'>
            {posts.map((post, index) =>
            <div onClick={() => selectClose(index)} className="thumbnails">
            <PostThumb key={post.id} props={post} state={fadeDirReversed} /></div>
            )}
            </div>
          </Suspense>
          </animated.div> : ''
        )}
      </div>
    </div>
  )
}

export default Portfolio